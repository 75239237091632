import { useState, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { styled, useTheme } from '@material-ui/core/styles';
import { useDispatch } from '../../redux/store';
import { getVehicleConfiguration } from '../../redux/slices/vehicles'
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import GroupSidebar from '../group/GroupSidebar';
import ProjectNavBar from './ProjectNavBar';
import DashboardNavbar from '../app/DashboardNavbar';
import DashboardSidebar from '../app/DashboardSidebar';
import ErrorComponent from '../../components/shared/error/ErrorComponent';
import { ProjectProvider } from '../../contexts/ProjectContext';
import { SubscriptionProvider } from '../../contexts/SubscriptionContext';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { CreateVehicleModal } from '../../components/vehicle';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { useGetProjectQuery } from '../../services/projects'
import { APP_BAR_MOBILE, APP_BAR_DESKTOP } from '../constants';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  height: '100vh',
  paddingTop: APP_BAR_MOBILE + 5,
  paddingBottom: theme.spacing(3), // No bottom padding, so we can fix things to the bottom?
  [theme.breakpoints.up('xl')]: {
    paddingTop: APP_BAR_DESKTOP + 5,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export default function ProjectLayout() {

  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const { isLoading, error } = useGetProjectQuery(id);

  useEffect(() => {
    dispatch(getVehicleConfiguration(id));
  }, [dispatch, id])

  if (error) {
    const err = error as FetchBaseQueryError;
    if (err.status == 404) {
      return (
        <RootStyle>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
          <MainStyle
            sx={{
              transition: theme.transitions.create('margin', {
                duration: theme.transitions.duration.complex
              }),
              ...(collapseClick && {
                ml: '102px'
              })
            }}
          >
            <ErrorComponent
              title="Hmm... We can't seem to find that project"
              description="Please check you are trying to access the correct project" />
          </MainStyle>
        </RootStyle>
      )
    }

    if (err.status == "FETCH_ERROR") {
      return (
        <RootStyle>
          <MainStyle
            sx={{
              transition: theme.transitions.create('margin', {
                duration: theme.transitions.duration.complex
              }),
              ...(collapseClick && {
                ml: '102px'
              })
            }}
          >
            <ErrorComponent
              showMaintenance={true}
              description="We seem to be having trouble connecting with our data services. Please check your network connection and try refreshing the page. If the issues persists please get in touch with support." />
          </MainStyle>
        </RootStyle>
      )
    }

    return (
      <RootStyle>
        <MainStyle
          sx={{
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.complex
            }),
            ...(collapseClick && {
              ml: '102px'
            })
          }}
        >
          <ErrorComponent
            showMaintenance={true}
            description="We seem to be having trouble connecting with our data services. Please check your network connection and try refreshing the page. If the issues persists please get in touch with support." />
        </MainStyle>
      </RootStyle>
    )
  }

  if (isLoading) {
    return (
      <RootStyle>
        <MainStyle
          sx={{
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.complex
            }),
            ...(collapseClick && {
              ml: '102px'
            })
          }}
        >
          <LoadingScreen />
        </MainStyle>
      </RootStyle>
    )
  }

  return (
    <RootStyle>
      <ProjectProvider identifier={id} key={id}>
        <SubscriptionProvider identifier={id}>
          <ProjectNavBar onOpenSidebar={() => setOpen(true)} />
          <GroupSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
          <MainStyle
            data-main="here"
            sx={{
              height: "100vh",
              transition: theme.transitions.create('margin', {
                duration: theme.transitions.duration.complex
              }),
              ...(collapseClick && {
                ml: '102px'
              })
            }}
          >
            <Outlet />
            <CreateVehicleModal />
          </MainStyle>
        </SubscriptionProvider>
      </ProjectProvider>
    </RootStyle>
  );
}
