export interface Integration {
    integrationKey: string;
    id: string;
    name: string;
    description: string;
    created: string;
    updated: string;
    status: IntegrationStatus;
    instance: IntegrationInstance | null;
}

export interface IntegrationInstance {
    instanceId: string;
    created: string;
    updated: Date | null;
    whenNextScheduled: string;
    status: IntegrationStatus;
    state: IntegrationState;
    latestError: string;
    authentication: IntegrationAuthentication;
    parameters: any;
    importState: ImportState;
}

export interface ImportState {
    whenCompleted: Date | null;
    whenRequested: Date | null;
    status: ImportStatus;
    stages: ImportStage[];
    contactStage: ImportStage;
    inventoryStage: ImportStage;
    invoicesPayableStage: ImportStage;
    invoicesReceivableStage: ImportStage;
}

export interface ImportStage {
    name: String;
    status: ImportStageStatus;
    startTime: Date | null;
    endTime: Date | null;
    errorMessage: string;
}

export interface DataConnectorConfig {
    type: String;
    enabled: boolean;
    active: boolean;
    parameters: {
        integrationBehaviour: IntegrationBehaviour;
    }
}

export enum IntegrationStatus {

    Unknown = "unknown",
    Created = "created",
    Enabled = "enabled",
    Maintenance = "maintenance",
    Disabled = "disabled",
}

export enum IntegrationState {
    Unknown = "Unknown",
    Configured = "Configured",
    Synchronised = "Synchronised",
    Ready = "Ready",
    Running = "Running",
    Error = "Error",
}

export enum IntegrationBehaviour {
    Push = "Push",
    Pull = "Pull",
    PushPull = "PushPull",
}

export enum ImportStatus {
    Unknown = "Unknown",
    Requested = "Requested",
    Running = "Running",
    Complete = "Complete",
    Skipped = "Skipped",
    Error = "Error",
}

export enum ImportStageStatus {
    Created = "Created",
    Requested = "Requested",
    Ignored = "Ignored",
    InProgress = "InProgress",
    Complete = "Complete",
    Error = "Error",
}

export interface IntegrationAuthentication {
    whenLastUpdatedUtc: string | null;
}

export interface IntegrationUpdateRequest {
    integrationKey: string;
    enabled?: boolean;
    parameters?: any
}

export interface IntegrationCreateRequest {
    integrationKey: string;
    startTime?: Date | null;
    parameters?: DataConnectorParameters
}

export interface DataConnectorParameters {
    integrationBehaviour: IntegrationBehaviour;
    contactsLastProcessed: Date | null;
    processInventory: boolean;
    inventoryLastProcessed: Date | null;
    invoicesPayable: InvoiceIntegrationParameters;
    invoicesReceivable: InvoiceIntegrationParameters;
}

export interface InvoiceIntegrationParameters {
    enabled: boolean;
    lastProcessed: Date | null;
}

export interface InvoiceIntegrationSyncRequest {
    skip: boolean;
    startTime?: Date | null;
    processInventory?: boolean;
    processInvoicesPayable?: boolean;
    processInvoicesReceivable?: boolean;
}
