import { useRef, useState } from 'react';
import { Menu, Paper, Button, Typography, Stack, Link, Alert } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { MIconButton } from '../../@material-extend';
import { fDateTime } from '../../../utils/formatTime'
import ExternalSyncStatusLabel from '../../_shared/SyncStatusLabel'
import { ExternalSyncStatus, SynchronisationState } from '../../../@types/tracking'
import { DataConnectorConfig, IntegrationBehaviour } from '../../../@types/integration'
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import SyncIcon from '@material-ui/icons/Sync';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import { useGetDataConnectorConfigQuery } from '../../../services/integrations';
import useProject from '../../../hooks/useProject';
import { useTheme } from '@material-ui/core/styles';

type InvoiceExternalSyncActionMenu = {
  organisationId: string;
  externalIdentifier: string | null | undefined;
  entityType: string;
  synchronisationState: SynchronisationState | null | undefined;
  viewEnabled?: boolean;
  onExternalOpen(): void;
  onExternalSync(direction: 'Push' | 'Pull'): Promise<void>;
}

function DataConnectorActionMenu({ organisationId, externalIdentifier, entityType, onExternalOpen, onExternalSync, viewEnabled = true, synchronisationState }: InvoiceExternalSyncActionMenu) {

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const { data: dataConnectorConfig, error } = useGetDataConnectorConfigQuery(organisationId);
  const { project } = useProject();
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (error) {
    return (
      <>
        <MIconButton ref={anchorRef} size="large" onClick={handleOpen} sx={{ padding: 1 }}>
          <SyncIcon />
        </MIconButton>
        <Menu
          open={open}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Stack direction="column" sx={{ p: 2, minWidth: '320px', maxWidth: '400px' }}>
            <Typography variant="h6">Data Connector</Typography>
            <Typography variant="caption" sx={{ mb: 1 }}>A data connector can synchronise data to and/or from an external system. For more details see your <Link sx={{ color: theme.palette.primary.dark }} href={`/group/${project?.identifier}/settings/vehicles`}>integrations</Link></Typography>
            <Alert severity='error'>Error occurred loading data connector configuration for organisation. Please contact support if the issue persists.</Alert>
          </Stack>
        </Menu >
      </>
    );
  }

  if (!synchronisationState) {
    return (
      <MIconButton ref={anchorRef} size="large" onClick={handleOpen} sx={{ padding: 1 }}>
        <SyncDisabledIcon width={20} height={20} />
      </MIconButton>
    );
  }

  return (
    <>
      <MIconButton ref={anchorRef} size="large" onClick={handleOpen} sx={{ padding: 1 }}>
        {synchronisationState.externalSyncStatus == ExternalSyncStatus.Error ? <SyncProblemIcon color='warning' /> : <SyncIcon />}
      </MIconButton>
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Stack direction="column" sx={{ p: 2, minWidth: '320px', maxWidth: '400px' }}>
          <Typography variant="h6">Data Connector</Typography>
          <Typography variant="caption" sx={{ mb: 2 }}>A data connector can synchronise data to and/or from an external system. For more details see your <Link sx={{ color: theme.palette.primary.dark }} href={`/group/${project?.identifier}/integrations`}>integrations</Link></Typography>
          <DataConnectorAction
            externalIdentifier={externalIdentifier}
            synchronisationState={synchronisationState}
            dataConnectorConfig={dataConnectorConfig}
            viewEnabled={viewEnabled}
            entityType={entityType}
            onExternalOpen={onExternalOpen}
            onExternalSync={onExternalSync}
          />
        </Stack>
      </Menu>
    </>
  );
}

type InvoiceExternalSyncActionProps = {
  entityType: string;
  externalIdentifier: string | null | undefined;
  synchronisationState: SynchronisationState;
  dataConnectorConfig: DataConnectorConfig | null | undefined;
  viewEnabled?: boolean;
  onExternalOpen(): void;
  onExternalSync(direction: 'Push' | 'Pull'): void;
}

function DataConnectorAction({ dataConnectorConfig, entityType, externalIdentifier, onExternalOpen, onExternalSync, viewEnabled, synchronisationState }: InvoiceExternalSyncActionProps) {

  const [syncLoading, setSyncLoading] = useState(false);

  const handleExternalSync = async (direction: 'Push' | 'Pull') => {
    try {
      setSyncLoading(true);
      await onExternalSync(direction);
      setSyncLoading(false);
    } catch {
      setSyncLoading(false);
    }

  };

  const getConnectorDescription = (dataConnectorConfig: DataConnectorConfig) => {
    switch (dataConnectorConfig.parameters.integrationBehaviour) {
      case IntegrationBehaviour.Push:
        return `Your data connector configuration is set to Push, this means that updates made to records will be automatically synchronised with ${dataConnectorConfig.type}, and any updates made in ${dataConnectorConfig.type} will not be synchronised.`;
      case IntegrationBehaviour.Pull:
        return `Your data connector configuration is set to Pull, this means that updates made to records in ${dataConnectorConfig.type} will be automatically synchronised, and any updates made here will not be pushed to ${dataConnectorConfig.type}.`;
      case IntegrationBehaviour.PushPull:
        return `Your data connector configuration is set to PushPull, this means that updates made to records here and in ${dataConnectorConfig.type} will be synchronised.`;
    }
  }

  if (!dataConnectorConfig) {
    return (
      <Stack direction="column">
        <Alert severity='info'>This organisation currently has no data connector configured.</Alert>
      </Stack>
    );
  }

  if (!dataConnectorConfig.enabled) {
    return (
      <Stack direction="column">
        <Alert severity='info'>This organisation currently has no data connector configured.</Alert>
      </Stack>
    );
  }

  return (
    <Stack direction="column">
      <Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 0.5 }}>
          <Typography variant="subtitle2">Synchronisation Status</Typography>
          <ExternalSyncStatusLabel status={synchronisationState.externalSyncStatus} />
        </Stack>

        {synchronisationState?.errorMessage && (<Paper sx={{ mb: 1, mt: 1, p: 1, bgcolor: 'background.neutral' }}><Typography variant="body2">Error: {synchronisationState?.errorMessage}</Typography></Paper>)}
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>Provider</Typography>
          <Typography variant="subtitle2">{dataConnectorConfig.type}</Typography>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>External Updated</Typography>
          <Typography variant="subtitle2">{synchronisationState.whenUpdated ? fDateTime(synchronisationState.whenUpdated) : '-'}</Typography>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>Last Synchronised</Typography>
          <Typography variant="subtitle2">{synchronisationState.whenSynchronised ? fDateTime(synchronisationState.whenSynchronised) : '-'}</Typography>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>Id</Typography>
          <Typography variant="subtitle2">{externalIdentifier ?? '-'}</Typography>
        </Stack>
        {viewEnabled && (<Button
          sx={{ mt: 2 }}
          onClick={onExternalOpen}
          disabled={!externalIdentifier}
          fullWidth
          variant="outlined"
        >
          View in Xero
        </Button>)}
      </Stack>
      <Typography variant="caption" sx={{ mb: 1, mt: 2 }}>{getConnectorDescription(dataConnectorConfig)}</Typography>
      <Stack direction="row" justifyContent="space-between">
        <LoadingButton
          loading={syncLoading}
          sx={{ mt: 2 }}
          onClick={() => handleExternalSync('Push')}
          fullWidth
          disabled={!dataConnectorConfig.active || dataConnectorConfig.parameters.integrationBehaviour === IntegrationBehaviour.Pull}
          variant="contained"
        >
          Push {entityType}
        </LoadingButton>
        <LoadingButton
          loading={syncLoading}
          sx={{ mt: 2, ml: 2 }}
          onClick={() => handleExternalSync('Pull')}
          fullWidth
          disabled={(!dataConnectorConfig.active || dataConnectorConfig.parameters.integrationBehaviour === IntegrationBehaviour.Push) || !externalIdentifier}
          variant="contained"
        >
          Pull {entityType}
        </LoadingButton>
      </Stack>
    </Stack >
  );
}

export default DataConnectorActionMenu;