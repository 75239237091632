import { useState } from 'react'
import { Grid, Card, Stack, CardHeader, Typography, CardContent, Button } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { ClientStatus, ClientSummary } from '../../@types/client'
import ClientUpdateModal from '../client/modal/ClientUpdateModal'
import { useSyncClientMutation } from '../../services/clients'
import LastUpdatedComponent from '../shared/LastUpdatedComponent'
import DataConnectorActionMenu from '../integration/data-connector/DataConnectorActionMenu'
import editFill from '@iconify/icons-eva/edit-fill';
import { useSnackbar } from 'notistack5';

type ClientDetailInfoProps = {
    client: ClientSummary;
};

export default function ClientDetailInfo({ client }: ClientDetailInfoProps) {

    const { enqueueSnackbar } = useSnackbar();
    const [syncClient] = useSyncClientMutation();
    const [editModalOpen, setEditModalOpen] = useState(false);

    const handleSyncRequest = async (direction: 'Push' | 'Pull') => {
        try {
            await syncClient({ clientId: client.id, direction }).unwrap();
            enqueueSnackbar(`External synchronisation request completed.`, { variant: 'success' });
        } catch (response) {
            if (response.data && response.data.errors) {
                Object.keys(response.data.errors).forEach((key: any) => {
                    let errors: string[] = Object.keys(response.data.errors[key]).map((errorKey: string) => {
                        return response.data.errors[key][errorKey];
                    })

                    enqueueSnackbar(errors, { variant: 'error' });
                })
            } else {
                enqueueSnackbar(`An error occurred while processing external synchronisation.`, { variant: 'error' });
            }
        }
    }

    if (!client) {
        return null
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                    <Card sx={{ mb: 4 }}>
                        <CardHeader
                            title="Contact Details"
                            action={
                                <>
                                    <DataConnectorActionMenu
                                        organisationId={''}
                                        externalIdentifier={client.externalIdentifier}
                                        entityType='Contact'
                                        synchronisationState={client.synchronisationState}
                                        //onDelete={() => { }}
                                        onExternalSync={handleSyncRequest}
                                        viewEnabled={false}
                                        onExternalOpen={() => {
                                            //const win = window.open(invoice.webUrl, "_blank");
                                            //win?.focus();
                                        }}
                                    />
                                </>
                            }
                        />
                        <CardContent>
                            <Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Name</Typography>
                                    <Typography variant="subtitle2">{client.name}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>First Name</Typography>
                                    <Typography variant="subtitle2">{client.firstName}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Last Name</Typography>
                                    <Typography variant="subtitle2">{client.lastName}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Email</Typography>
                                    <Typography variant="subtitle2">{client.emailAddress}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Phone</Typography>
                                    <Typography variant="subtitle2">{client.phoneNumber}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Account Number</Typography>
                                    <Typography variant="subtitle2">{client.accountNumber}</Typography>
                                </Stack>
                                <LastUpdatedComponent updated={client.updated} />
                                <Stack spacing={3} alignItems="flex-end" sx={{ pt: 2 }}>
                                    <Button
                                        size="small"
                                        startIcon={<Icon icon={editFill} />}
                                        disabled={client.status !== ClientStatus.Active}
                                        onClick={() => setEditModalOpen(true)}
                                    >
                                        Edit Customer
                                    </Button>
                                </Stack>
                                <ClientUpdateModal
                                    open={editModalOpen}
                                    client={client}
                                    onModalClose={() => setEditModalOpen(false)}
                                    onUpdated={() => setEditModalOpen(false)}
                                />
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
                {/* <Stack spacing={2}>
                    <Card sx={{ mb: 4 }}>
                        <CardHeader title="External Sync" subheader='Customers can be synchronised to and from your external accounting provider. The external id is the unique identifier of the customer in the source system.' />
                        <CardContent>
                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between">
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>Status</Typography>
                                <ExternalSyncStatusLabel status={client.synchronisationState?.externalSyncStatus ?? ExternalSyncStatus.Unknown} />
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between">
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>External Updated</Typography>
                                <Typography variant="caption">
                                    {client.synchronisationState ? client.synchronisationState.whenUpdated ? fDateTime(client.synchronisationState.whenUpdated) : '-' : '-'}
                                </Typography>
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between">
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>Last Sync</Typography>
                                <Typography variant="caption">
                                    {client.synchronisationState ? client.synchronisationState.whenSynchronised ? fDateTime(client.synchronisationState.whenSynchronised) : '-' : '-'}
                                </Typography>
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between">
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>External Id</Typography>
                                <Typography variant="caption">
                                    {client.externalIdentifier || '-'}
                                </Typography>
                            </Stack>
                            <Stack alignItems="flex-end">
                                <LoadingButton loading={syncLoading} onClick={() => handleSyncRequest('Push')}>Push</LoadingButton>
                                <LoadingButton loading={syncLoading} onClick={() => handleSyncRequest('Pull')}>Pull</LoadingButton>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack> */}
            </Grid>
        </Grid>
    );
}
