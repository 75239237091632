
export type SynchronisationState = {
    externalSyncStatus: ExternalSyncStatus;
    whenRequested: Date
    whenSynchronised: Date | null;
    whenUpdated: Date | null;
    syncRequests: number;
    errorMessage: string | null;
}

export enum ExternalSyncStatus {
    Unknown = "Unknown",
    NotApplicable = "NotApplicable",
    Requested = "Requested",
    Modified = "Modified",
    Synchronised = "Synchronised",
    Error = "Error",
}

