import { useEffect } from 'react';
import { TableCell, Stack } from '@material-ui/core';
import { useQueryParams, ArrayParam, NumberParam, StringParam, BooleanParam } from 'use-query-params';
import { Invoice, InvoiceStatus, InvoiceSearchCriteria } from '../../@types/invoice';
import { fDateShort } from '../../utils/formatTime'
import useProject from '../../hooks/useProject'
import { fNumberFinancial } from '../../utils/formatNumber'
import { InvoiceTable, InvoiceStatusSearchMenu } from '../../components/invoice';
import { InvoiceStatusLabel } from '../../components/_invoice';
import SearchFilter from '../../components/search/SearchFilter';
import SearchInput from '../search/SearchInput'
import { useTheme } from '@material-ui/core/styles';

const TABLE_HEAD = [
  {
    id: 'invoiceNumber',
    numeric: false,
    disablePadding: false,
    label: 'Number',
    sortable: true,
    width: 100,
  },
  {
    id: 'entity',
    numeric: false,
    disablePadding: false,
    label: 'Client',
    sortable: true,
    width: 250,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    sortable: true,
    width: 110,
  },
  {
    id: 'reference',
    numeric: false,
    disablePadding: false,
    label: 'Reference',
    sortable: true,
    width: 500,
  },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'Due Date',
    sortable: true,
    width: 150,
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    label: 'Total',
    sortable: true,
  }
];

export type InvoiceSearchProps = {
  invoices: Invoice[];
  isLoading: boolean;
  totalRows: number;
  showSearch?: boolean;
  search: InvoiceSearchCriteria;
  selectedRow?: string[],
  showCustomer?: boolean;
  onSetSearch(query: any): void;
  onSearch(): void;
  setSelectRow?(selectedRow: (string | null)[]): void;
}

type SearchProps = {
  statuses: InvoiceStatus[];
  onChange(statuses: InvoiceStatus[]): void;
}

export function Search({ statuses, onChange }: SearchProps) {

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} >
      <SearchFilter
        name={'Status Filter'}
        active={statuses.length > 0}
        searchComponent={
          <InvoiceStatusSearchMenu
            statuses={statuses}
            onChange={onChange}
          />
        }
      />
    </Stack>
  )
}

export default function InvoiceSearch({
  invoices,
  search,
  isLoading,
  totalRows,
  selectedRow = [],
  showCustomer = true,
  onSetSearch,
  onSearch,
  setSelectRow,
  showSearch = true
}: InvoiceSearchProps) {

  const theme = useTheme();
  const { project } = useProject();
  const DEFAULT_ORDER = 'desc';
  const DEFAULT_ORDER_BY = 'dueDate';
  const tableHeaders = showCustomer ? TABLE_HEAD : TABLE_HEAD.filter((header) => header.id !== 'entity');

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    pageSize: NumberParam,
    order: StringParam,
    orderBy: StringParam,
    unlinkedOnly: BooleanParam,
    statusFilter: ArrayParam,
    text: StringParam,
  });

  const [selectedRowQuery, setSelectedRowQuery] = useQueryParams({
    selected: ArrayParam,
  });

  useEffect(() => {
    const { statusFilter, text, page, pageSize, order, orderBy, unlinkedOnly } = query;

    // set the search query based on the url change
    onSetSearch({
      ...search,
      pageNumber: page ?? 0,
      pageSize: pageSize ?? 50,
      order: order ?? DEFAULT_ORDER,
      orderBy: orderBy ?? DEFAULT_ORDER_BY,
      unlinkedOnly: unlinkedOnly ?? false,
      statusFilter: statusFilter ?? search.statusFilter,
      text: text ?? '',
    });

    // search invoices
    onSearch();
  }, [query]);

  useEffect(() => {
    const { selected } = selectedRowQuery;
    setSelectRow && setSelectRow(selected ?? [])
  }, [selectedRowQuery]);

  function handleChangePage(newPage: number) {
    setQuery({ page: newPage }, 'pushIn');
  };

  function handleChangePageSize(newPageSize: number) {
    setQuery({ pageSize: newPageSize, page: 0 }, 'pushIn');
  };

  function handleRowSelect(selectedRow: string[]) {
    setSelectedRowQuery({ selected: selectedRow }, 'pushIn');
  };

  function handleSort(orderBy: string, order: 'asc' | 'desc') {
    setQuery({
      orderBy: orderBy == DEFAULT_ORDER_BY ? undefined : orderBy,
      order: order == DEFAULT_ORDER ? undefined : order
    }, 'pushIn');
  }

  const handleChangeSearch = (search: InvoiceSearchCriteria) => {
    // sets the search in the url
    setQuery(
      {
        statusFilter: search.statusFilter,
        text: search.text ? search.text.length === 0 ? undefined : search.text : undefined,
        unlinkedOnly: search.unlinkedOnly ? true : false,
      }, 'pushIn');
  }

  return (
    <>
      {showSearch ?

        <Stack sx={{ mb: 2 }} direction={{ xs: 'column', sm: 'row' }} spacing={2} flexGrow={0.5} justifyContent="flex-start">
          <SearchInput
            label={'Search'}
            placeholder="Search.."
            onInputChange={(value) => handleChangeSearch({ ...search, text: value })}
            value={search.text}
          />
          <Search statuses={search.statusFilter} onChange={(statuses) => handleChangeSearch({ ...search, statusFilter: statuses })} />
        </Stack>

        : null}
      <InvoiceTable
        invoices={invoices}
        showSearch={showSearch}
        search={search}
        selectedRow={selectedRowQuery.selected ? selectedRowQuery.selected as string[] : []}
        totalRows={totalRows}
        project={project}
        isLoading={isLoading}
        header={tableHeaders}
        setSelectRow={(selectedRow) => handleRowSelect(selectedRow)}
        handleChangePage={handleChangePage}
        handleChangePageSize={handleChangePageSize}
        handleSort={handleSort}
        handleSearchChange={handleChangeSearch}
        renderTableRow={(row: Invoice, selected: boolean, labelId: string) => {
          return (
            <>
              <TableCell align="left" width={100}><a style={{ textDecoration: 'underline', color: theme.palette.primary.dark }}>{row.invoiceNumber}</a></TableCell>
              {showCustomer && (<TableCell align="left" style={{ minWidth: 250 }}>{row.entity.name}</TableCell>)}
              <TableCell align="left" width={100}><InvoiceStatusLabel status={row.status} /></TableCell>
              <TableCell align="left">{row.reference}</TableCell>
              <TableCell align="left" width={120}>{row.dueDate ? fDateShort(row.dueDate) : '-'}</TableCell>
              <TableCell align="right">${fNumberFinancial(row.total)}</TableCell>
            </>
          )
        }}
      />
    </>
  );
}
