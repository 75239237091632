import React, { useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClientSummary, Person } from '../../../@types/client'
import { Phone, PhoneType } from '../../../@types/contact'
import { Stack, TextField, Button, Box, Alert, Typography, Menu, MenuItem, Divider } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useSnackbar } from 'notistack5';
import * as Yup from 'yup';
import * as jsonpatch from 'fast-json-patch';
import { usePatchClientMutation } from '../../../services/clients'
import 'react-phone-input-2/lib/style.css'
import _ from 'lodash';

import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import plusFill from '@iconify/icons-eva/plus-fill';

type ClientUpdateFormProps = {
    client: ClientSummary;
    onUpdated(client: ClientSummary): void;
};

type ClientUpdateFormValues = {
    name: string;
    projectId: string;
    general: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    accountNumber: string | null;
    isCustomer: boolean;
    isSupplier: boolean;
    phoneNumber: string;
    phones: Phone[];
    maintenanceContacts: Person[];
};

export const FormSchema = Yup.object().shape({
    name: Yup.string()
        .required('Client name is required')
        .min(3),
    firstName: Yup.string().nullable(),
    general: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    emailAddress: Yup.string().email('Email must be a valid email address').nullable(),
    accountNumber: Yup.string().nullable(),
    phones: Yup.array().of(Yup.object({
        phoneType: Yup.string().required(),
        phoneCountryCode: Yup.string().nullable(),
        phoneAreaCode: Yup.string().nullable(),
        phoneNumber: Yup.string().required('Phone number is required'),
    })),
    maintenanceContacts: Yup.array().of(Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email('Email must be a valid email address').nullable(),
        phone: Yup.object({
            phoneType: Yup.string().required(),
            phoneCountryCode: Yup.string().nullable(),
            phoneAreaCode: Yup.string().nullable(),
            phoneNumber: Yup.string().required('Phone number is required'),
        }),
    }))
});

export default function ClientUpdateForm({ client, onUpdated }: ClientUpdateFormProps) {

    const { enqueueSnackbar } = useSnackbar();
    const [patchClient] = usePatchClientMutation();

    const {
        control,
        handleSubmit,
        setError,
        formState: { isSubmitting, isValid, isDirty, errors }
    } = useForm<ClientUpdateFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(FormSchema),
        defaultValues: {
            name: client.name,
            firstName: client.firstName,
            lastName: client.lastName,
            emailAddress: client.emailAddress,
            accountNumber: client.accountNumber,
            phoneNumber: client.phoneNumber,
            isCustomer: client.isCustomer,
            isSupplier: client.isSupplier,
            phones: client.phones,
            maintenanceContacts: client.maintenanceContacts || []
        }
    })

    const onSubmit = async (data: ClientUpdateFormValues) => {

        try {
            var document = jsonpatch.deepClone(client);
            var observer = jsonpatch.observe<ClientSummary>(document);

            document.name = data.name;
            document.firstName = data.firstName;
            document.lastName = data.lastName;
            document.emailAddress = data.emailAddress;
            document.accountNumber = data.accountNumber;
            document.phoneNumber = data.phoneNumber;
            document.phones = data.phones;
            document.maintenanceContacts = data.maintenanceContacts;

            var patch = jsonpatch.generate(observer);

            const result = await patchClient({
                clientId: client.id,
                patch: patch
            }).unwrap();

            onUpdated(result);
            //enqueueSnackbar(`Update completed successfully`, { variant: 'success' });
        }
        catch (response) {
            console.log(errors)
            if (response.data && response.data.errors) {
                Object.keys(response.data.errors).forEach((key: any) => {
                    let errors: string[] = Object.keys(response.data.errors[key]).map((errorKey: string) => {
                        return response.data.errors[key][errorKey];
                    })

                    // set the erorrs on the form
                    setError(key, {
                        type: "manual",
                        message: errors.join(',')
                    })
                })
            } else {
                enqueueSnackbar(`An error occurred while updating contact.`, { variant: 'error' });
            }
        }
    };

    const { fields, remove, append } = useFieldArray({ control, name: "phones" });
    const { fields: additionalPeople, remove: removePerson, append: appendPerson } = useFieldArray({ control, name: "maintenanceContacts" });

    return (
        <form>
            <Stack spacing={2}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Contact Name (required)"
                            error={Boolean(error)}
                            helperText={error?.message}
                            autoComplete={'off'}
                        />
                    )}
                />
                <Controller
                    name="accountNumber"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Account Number"
                            error={Boolean(error)}
                            helperText={error?.message}
                            autoComplete={'off'}
                        />
                    )}
                />
                <Typography variant="subtitle2">Primary Person</Typography>
                <Stack direction="row" spacing={1}>
                    <Controller
                        name="firstName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="First Name"
                                error={Boolean(error)}
                                helperText={error?.message}
                                autoComplete={'off'}
                                fullWidth
                            />
                        )}
                    />
                    <Controller
                        name="lastName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="Last Name"
                                error={Boolean(error)}
                                helperText={error?.message}
                                autoComplete={'off'}
                                fullWidth
                            />
                        )}
                    />
                </Stack>
                <Controller
                    name="emailAddress"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Email"
                            error={Boolean(error)}
                            helperText={error?.message}
                            autoComplete={'off'}
                        />
                    )}
                />
                {fields.map((phone, index) => {
                    return (
                        <>
                            <Typography variant="subtitle2">{phone.phoneType} Number</Typography>
                            <Stack direction="row" spacing={0.5} key={phone.id}>
                                <Controller
                                    name={`phones.${index}.phoneCountryCode`}
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            placeholder='Country'
                                            label='Country'
                                            error={Boolean(error)}
                                            helperText={error?.message}
                                            autoComplete={'off'}
                                            type='number'
                                            size="small"
                                            sx={{ width: 150 }}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`phones.${index}.phoneAreaCode`}
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            placeholder='Area'
                                            label='Area'
                                            error={Boolean(error)}
                                            helperText={error?.message}
                                            autoComplete={'off'}
                                            type='number'
                                            size="small"
                                            sx={{ width: 200 }}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`phones.${index}.phoneNumber`}
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            placeholder='Number'
                                            label='Number'
                                            error={Boolean(error)}
                                            helperText={error?.message}
                                            autoComplete={'off'}
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                    )}
                                />
                                <Box sx={{ pt: 0.5, cursor: 'pointer' }}>
                                    <Icon width={30} icon={closeFill} onClick={() => remove(index)} />
                                </Box>
                            </Stack>
                        </>
                    );
                })}
                <AddPhoneButton
                    phones={fields.map(phone => phone.phoneType)}
                    onAddPhone={(type) => append({
                        phoneType: type,
                        phoneCountryCode: 64,
                        phoneAreaCode: '',
                        phoneNumber: ''
                    })}
                />
                <Typography variant="h6">Maintenance Contact</Typography>
                <Typography marginTop={0} variant="caption">These are optional people who will receive updates relating to vehicle maintenance (e.g. a workshop manager or driver). If no maintenance contacts are set, the primary contact will be used.</Typography>
                {additionalPeople.map((person, index) => {
                    return (
                        <Stack direction="row">
                            <Stack spacing={1} key={person.id}>

                                <Stack direction="row" spacing={1}>
                                    <Controller
                                        name={`maintenanceContacts.${index}.firstName`}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                placeholder='First Name'
                                                label='First Name'
                                                error={Boolean(error)}
                                                helperText={error?.message}
                                                autoComplete={'off'}
                                                size="small"
                                                fullWidth
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`maintenanceContacts.${index}.lastName`}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                placeholder='Last Name'
                                                label='Last Name'
                                                error={Boolean(error)}
                                                helperText={error?.message}
                                                autoComplete={'off'}
                                                size="small"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Controller
                                        name={`maintenanceContacts.${index}.email`}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                placeholder='Email'
                                                label='Email'
                                                error={Boolean(error)}
                                                helperText={error?.message}
                                                autoComplete={'off'}
                                                size="small"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack direction="row" spacing={0.5}>
                                    <Controller
                                        name={`maintenanceContacts.${index}.phone.phoneCountryCode`}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                placeholder='Country'
                                                label='Country'
                                                error={Boolean(error)}
                                                helperText={error?.message}
                                                autoComplete={'off'}
                                                size="small"
                                                type='number'
                                                sx={{ width: 150 }}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`maintenanceContacts.${index}.phone.phoneAreaCode`}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                placeholder='Area'
                                                label='Area'
                                                error={Boolean(error)}
                                                helperText={error?.message}
                                                autoComplete={'off'}
                                                size="small"
                                                type='number'
                                                sx={{ width: 200 }}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`maintenanceContacts.${index}.phone.phoneNumber`}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                placeholder='Number'
                                                label='Number'
                                                error={Boolean(error)}
                                                helperText={error?.message}
                                                autoComplete={'off'}
                                                type='number'
                                                size="small"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Stack>
                                <Divider sx={{ pt: 2 }} />
                            </Stack>
                            <Box sx={{ pl: 0.5, pt: 0.5, cursor: 'pointer' }}>
                                <Icon width={30} icon={closeFill} onClick={() => removePerson(index)} />
                            </Box>

                        </Stack>
                    );
                })}
                <Box>
                    <Button
                        id="add-person-button"
                        startIcon={<Icon icon={plusFill} />}
                        onClick={() => appendPerson({
                            firstName: '',
                            lastName: '',
                            email: '',
                            phone: {
                                phoneType: PhoneType.Mobile,
                                phoneCountryCode: 64,
                                phoneAreaCode: '',
                                phoneNumber: ''
                            }
                        })}
                    >
                        Add Maintenance Contact
                    </Button>
                </Box>
                {
                    errors.projectId && <Alert severity="error" sx={{ mb: 3 }}>{errors.projectId.message}</Alert>
                }
                {
                    errors.general && <Alert severity="error" sx={{ mb: 3 }}>{errors.general.message}</Alert>
                }
                <LoadingButton
                    fullWidth
                    size="large"
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isValid || !isDirty}
                >
                    Update Customer
                </LoadingButton>
            </Stack>
        </form >
    );
}

const AddPhoneButton = ({ onAddPhone, phones = [] }: { phones?: PhoneType[], onAddPhone(type: PhoneType): void }) => {

    const allPhoneTypes = [
        {
            name: 'Phone Number',
            type: PhoneType.DirectDial,
        },
        {
            name: 'Mobile Number',
            type: PhoneType.Mobile,
        },
        {
            name: 'Fax Number',
            type: PhoneType.Fax,
        }
    ];
    const availablePhoneTypes = allPhoneTypes.filter(item => !phones.includes(item.type));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Button
                id="add-phone-button"
                aria-controls={open ? 'add-phone-number-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                startIcon={<Icon icon={plusFill} />}
                onClick={handleClick}
            >
                Add Phone Number
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {availablePhoneTypes.map((phoneType) => (
                    <MenuItem
                        onClick={() => {
                            onAddPhone(phoneType.type);
                            handleClose();
                        }}
                    >{phoneType.name}</MenuItem>
                ))}
            </Menu>
        </Box>
    )
}
