import { InventoryItem, InventoryItemSearchCriteria, InventoryItemSearchResult, InventoryItemUpdateRequest, InventoryItemCreateRequest } from '../@types/inventory'
import { api } from './api'

export const inventoryApi = api.injectEndpoints({
  endpoints: (builder) => ({

    getInventoryItems: builder.query<InventoryItemSearchResult, InventoryItemSearchCriteria>({
      query: (search: InventoryItemSearchCriteria) => ({
        url: `inventory/search`,
        method: 'POST',
        body: search,
      }),
      providesTags: ['InventoryItem']
    }),

    getInventoryItem: builder.query<InventoryItem, string>({
      query: (id: string) => ({
        url: `inventory/${id}`,
        method: 'GET',
      }),
      providesTags: ['InventoryItem']
    }),

    createInventoryItem: builder.mutation<InventoryItem, InventoryItemCreateRequest>({
      query: (body: InventoryItemCreateRequest) => ({
        url: `inventory`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['InventoryItem']
    }),

    updateInventoryItem: builder.mutation<InventoryItem, InventoryItemUpdateRequest>({
      query: (request: InventoryItemUpdateRequest) => ({
        url: `inventory/${request.itemId}`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['InventoryItem']
    }),

    requestInventorySync: builder.mutation<InventoryItem, { itemId: string, direction: 'Push' | 'Pull' }>({
      query: (request: { itemId: string }) => ({
        url: `inventory/${request.itemId}/synchronise`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['InventoryItem']
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetInventoryItemsQuery,
  useGetInventoryItemQuery,
  useCreateInventoryItemMutation,
  useUpdateInventoryItemMutation,
  useRequestInventorySyncMutation,
} = inventoryApi;