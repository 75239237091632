import { Integration, ImportState, DataConnectorConfig, IntegrationUpdateRequest, IntegrationCreateRequest, InvoiceIntegrationSyncRequest } from '../@types/integration'
import { api } from './api'

export const integrationsApi = api.injectEndpoints({
  endpoints: (builder) => ({

    getIntegrations: builder.query<{ integrations: Integration[] }, void>({
      query: () => ({
        url: `/integrations`,
        method: 'GET'
      }),
      providesTags: ['Integration']
    }),

    getIntegration: builder.query<Integration, string>({
      query: (integrationKey: string) => ({
        url: `/integrations/${integrationKey}`,
        method: 'GET'
      }),
      providesTags: ['Integration']
    }),

    addIntegration: builder.mutation<Integration, IntegrationCreateRequest>({
      query: (request: IntegrationCreateRequest) => ({
        url: `/integrations/${request.integrationKey}`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Integration']
    }),

    updateIntegration: builder.mutation<Integration, IntegrationUpdateRequest>({
      query: (request: IntegrationUpdateRequest) => ({
        url: `/integrations/${request.integrationKey}`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Integration']
    }),

    synchroniseIntegration: builder.mutation<Integration, InvoiceIntegrationSyncRequest>({
      query: (request: InvoiceIntegrationSyncRequest) => ({
        url: `/integrations/Xero/sync`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Integration']
    }),

    getSynchroniseState: builder.query<ImportState, void>({
      query: () => ({
        url: `/integrations/Xero/sync`,
        method: 'GET',
      }),
      providesTags: ['Integration']
    }),

    getDataConnectorConfig: builder.query<DataConnectorConfig, string>({
      query: () => ({
        url: `/integrations/data-connector`,
        method: 'GET',
      }),
      providesTags: ['Integration'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetIntegrationQuery,
  useGetIntegrationsQuery,
  useUpdateIntegrationMutation,
  useAddIntegrationMutation,
  useSynchroniseIntegrationMutation,
  useGetSynchroniseStateQuery,
  useGetDataConnectorConfigQuery
} = integrationsApi;