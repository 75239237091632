import { ISearchCriteria } from './search'
import { SynchronisationState } from './tracking'
import { Phone } from './contact'

export type ClientSummary = {
    id: string;
    externalIdentifier: string | null;
    name: string;
    firstName?: string;
    lastName?: string;
    created: Date;
    updated: Date;
    emailAddress?: string;
    isCustomer: boolean;
    isSupplier: boolean;
    phoneNumber?: string;
    phones: Phone[];
    accountNumber?: string;
    status: ClientStatus;
    synchronisationState?: SynchronisationState;
    maintenanceContacts?: Person[];
}

export type ClientCreateRequest = {
    projectId: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    accountNumber?: string;
    phoneNumber?: string;
    isCustomer?: boolean;
    isSupplier?: boolean;
}

export type ClientSyncRequest = {
    clientId: string;
    direction: 'Push' | 'Pull';
}

export type Person = {
    firstName: string;
    lastName: string;
    email: string;
    phone: Phone;
}

export interface IClientSearchCriteria extends ISearchCriteria {
    activeOnly?: boolean
}

export enum ClientStatus {
    Unknown = "Unknown",
    Active = "Active",
    Archived = "Archived",
    GDPRRequest = "GDPRRequest",
}

export type ClientComplianceConfiguration = {
    trackingEnabled: boolean;
}